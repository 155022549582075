.link {
    margin-top: var(--spacing-core-2);
    padding: 0 var(--spacing-core-4);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-core-2);
    color: inherit;
    text-decoration: none;
    
    > * {
        text-transform: capitalize;
    }
}

.dash {
    width: var(--sizing-core-6);
}

.title {
    font-weight: 300;
}

.svg {
    flex-shrink: 0;
}
